import styled from 'styled-components';
import globalTokens from '../Theme/tokens/global';

export const LabelStyled = styled.label`
  font-family: 'Montserrat';
  font-weight: bold;
  color: ${globalTokens.colors.brand};
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
`;

export const ButtonStyled = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const PasswordContainer = styled.div`
  border-radius: 4px;
`;

export const IconContainer = styled.div`
  float: right;
  margin-right: 16px;
  margin-top: -33px;
  position: relative;
  z-index: 2;
  height: 100%;
  width: 26px;
`;

export const FileInputContainer = styled.div`
  position: relative;
`;

export const FileInputStyled = styled.input`
  opacity: 0;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
`;

import global from './global';

const rangeInput = {
  thumb: {
    extend: () => `
      background-color: ${global.colors.brand};
      width: 32px;
      height: 32px;
      top: -4px;
      border: 2px solid ${global.colors.neutral2};
      box-sizing: border-box;
      box-shadow: none !important;
      outline: none !important;
    `
  }
};

export default rangeInput;

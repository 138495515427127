import React from 'react';
import { FormControlContent, Label, Message } from './styles';

export interface IFormControlProps {
  label?: string;
  error?: boolean;
  message?: string;
}

const FormControl: React.FC<IFormControlProps> = ({
  label,
  message,
  error = false,
  children
}) => (
  <FormControlContent $error={error}>
    {label && <Label>{label}</Label>}
    {children}
    {message && <Message>{message}</Message>}
  </FormControlContent>
);

export default FormControl;

import React from 'react';

export interface ICheckboxGroupContextProps {
  name: string;
  onChange: (value: any[], event: React.ChangeEvent<HTMLInputElement>) => void;
  value: any[];
}

const CheckboxGroupContext = React.createContext<
  ICheckboxGroupContextProps | undefined
>(undefined);

export default CheckboxGroupContext;

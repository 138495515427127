import global from './global';

const paragraph = {
  color: global.colors.neutral1,
  extend: () => `
    font-size: 16px;
    max-width: initial;
  `
};

export default paragraph;

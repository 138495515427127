import styled from 'styled-components';
import { Col } from 'react-grid-system';

export const StyledCol = styled(Col)`
  && {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
`;

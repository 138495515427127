import React from 'react';
import { TipProps, Tip } from 'grommet';

import Caption from '../Typography/Caption';
import { Content, StyledDescription, ChildrenContent } from './styles';

interface ITooltipProps extends TipProps {
  title?: string;
  description?: string;
}

const Tooltip: React.FC<ITooltipProps> = ({
  title,
  description,
  children,
  ...props
}) => (
  <Tip
    plain={true}
    content={
      <Content>
        {title && <StyledDescription>{title}</StyledDescription>}
        {description && <Caption>{description}</Caption>}
      </Content>
    }
    {...props}
  >
    <ChildrenContent>{children}</ChildrenContent>
  </Tip>
);

export default Tooltip;

import React, { Fragment } from 'react';
import GoogleFontLoader from 'react-google-font-loader';

const baseFont = {
  family: 'Montserrat',
  weight: [400, 600, 700]
};

const FontLoader: React.FC = () => (
  <Fragment>
    {typeof window !== 'undefined' ? (
      <GoogleFontLoader
        fonts={[
          { font: baseFont.family, weights: baseFont.weight },
          { font: 'Material+Icons' }
        ]}
      />
    ) : (
      <Fragment>
        <link
          rel='stylesheet'
          href={`https://fonts.googleapis.com/css?family=${
            baseFont.family
          }:${baseFont.weight.join()}`}
        />
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/icon?family=Material+Icons'
        />
      </Fragment>
    )}
  </Fragment>
);

export default FontLoader;

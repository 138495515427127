import React from 'react';
import { IStepperProps } from './types';
import global from '../Theme/tokens/global';
import Icon from '../Icon';
import Subtitle from '../Typography/Subtitle';
import {
  HorizontalStepperContainer,
  VerticalStepperContainer,
  HorizontalStep,
  VerticalStep,
  HorizontalTitle,
  VerticalTitle,
  Bullet
} from './styles';

const stepIcons = (status) => {
  const iconByStatus = {
    success: 'check',
    error: 'error',
    info: 'info',
    warning: 'warning',
    unchecked: null,
    default: null
  };

  return iconByStatus[status] || iconByStatus['default'];
};

const stepColors = (status) => {
  const colorByStatus = {
    success: {
      background: global.colors.feedback1,
      text: global.colors.neutral5
    },
    error: {
      background: global.colors.feedback3,
      text: global.colors.neutral5
    },
    info: {
      background: global.colors.feedback2,
      text: global.colors.neutral5
    },
    warning: {
      background: global.colors.feedback4,
      text: global.colors.neutral5
    },
    unchecked: {
      background: global.colors.neutral2,
      text: global.colors.neutral4
    },
    default: {
      background: global.colors.brand3,
      text: global.colors.neutral5
    }
  };
  return colorByStatus[status] || colorByStatus['default'];
};

const Stepper: React.FC<IStepperProps> = ({
  steps,
  inverse = false,
  variant = 'horizontal',
  onStepClick,
  ...props
}) => {
  const StepperContainer =
    variant === 'horizontal'
      ? HorizontalStepperContainer
      : VerticalStepperContainer;

  const Step = variant === 'horizontal' ? HorizontalStep : VerticalStep;
  const Title = variant === 'horizontal' ? HorizontalTitle : VerticalTitle;

  return (
    <StepperContainer {...props}>
      {steps?.map((step, index) => (
        <Step
          key={index}
          inverse={inverse}
          onClick={() => {
            if (!step.disabled && onStepClick) {
              onStepClick(step);
            }
          }}
          {...step.props}
        >
          <Bullet
            disabled={step.disabled}
            status={step.status}
            bgColor={
              step.color ? step.color : stepColors(step.status).background
            }
            variant={variant}
          >
            <Icon
              icon={step.icon || stepIcons(step.status)}
              size={16}
              color={stepColors(step.status)?.text}
            />
            <Subtitle
              color={stepColors(step.status)?.text || global.colors.neutral5}
            >
              {index}
            </Subtitle>
          </Bullet>
          <Title
            selected={step.selected}
            disabled={step.disabled}
            inverse={inverse}
          >
            {step.title}
          </Title>
        </Step>
      ))}
    </StepperContainer>
  );
};

export default Stepper;

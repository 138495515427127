import React, { useState } from 'react';
import FormControl, { IFormControlProps } from '../FormControl';
import CheckboxGroupContext from './CheckboxGroupContext';
import { StyledCheckboxGroup } from './styles';

interface IRadioGroupProps extends IFormControlProps {
  value?: any[];
  name: string;
  onChange?: (value: any, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioGroup: React.FC<IRadioGroupProps> = ({
  label,
  message,
  error = false,
  children,
  name,
  value: valueProp,
  onChange
}) => {
  const [value, setValue] = useState(valueProp || []);

  const providerValue = {
    name,
    onChange: (value, event) => {
      setValue(value);

      if (onChange) {
        onChange(value, event);
      }
    },
    value
  };

  return (
    <CheckboxGroupContext.Provider value={providerValue}>
      <FormControl label={label} message={message} error={error}>
        <StyledCheckboxGroup role='group'>{children}</StyledCheckboxGroup>
      </FormControl>
    </CheckboxGroupContext.Provider>
  );
};

export default RadioGroup;

import React from 'react';
import { SpinnerExtendedProps } from 'grommet';
import { StyledSpinner } from './styles';

interface ISpinnerProps extends SpinnerExtendedProps {
  inverse?: boolean;
}

const Spinner: React.FC<ISpinnerProps> = ({ inverse = false, ...props }) => (
  <StyledSpinner $inverse={inverse} {...props} />
);

export default Spinner;

import styled from 'styled-components';
import { global } from '../Theme/tokens';
import Description from '../Typography/Description';

export const Content = styled.div<{ $color: string }>`
  width: 340px;
  min-height: 58px;
  padding: 18px;
  border-radius: 4px;
  background-color: ${(props) => props.$color};
  margin-bottom: 24px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  gap: 18px;
  opacity: 1;
  color: ${global.colors.neutral5};
  pointer-events: all;
`;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: 0.2s all;

  &:hover {
    opacity: 0.5;
  }
`;

export const Text = styled(Description)`
  font-weight: 600;
  font-family: 'Montserrat';
  line-height: 120%;
  flex: 1;
`;

import global from './global';

const heading = {
  color: global.colors.neutral1,
  extend: () => `
    max-width: initial !important;
  `,
  level: {
    1: {
      font: { weight: '700' },
      small: { size: '32px', height: '120%' },
      medium: { size: '40px', height: '120%' },
      large: { size: '40px', height: '120%' }
    },
    2: {
      font: { weight: '700' },
      small: { size: '24px', height: '120%' },
      medium: { size: '32px', height: '120%' },
      large: { size: '32px', height: '120%' }
    },
    3: {
      font: { weight: '700' },
      small: { size: '20px', height: '120%' },
      medium: { size: '24px', height: '120%' },
      large: { size: '24px', height: '120%' }
    }
  }
};

export default heading;

import styled from 'styled-components';
import { global } from '../Theme/tokens';

export const StyledDivider = styled.div<{
  $size: number;
  $margin: number;
  $inverse: boolean;
}>`
  border-top-width: ${(props) => props.$size}px;
  border-top-color: ${(props) =>
    props.$inverse ? global.colors.brand3 : global.colors.neutral3};
  border-top-style: solid;
  margin: ${(props) => props.$margin}px 0px;
`;

import styled from 'styled-components';
import global from '../Theme/tokens/global';
import Subtitle from '../Typography/Subtitle';
import Caption from '../Typography/Caption';

export const FormControlContent = styled.div<{ $error: boolean }>`
  display: flex;
  flex-direction: column;

  && input,
  select,
  button,
  textarea {
    border-color: ${(props) =>
      props.$error ? global.colors.feedback3 : global.control.border.color};
    outline-color: ${(props) =>
      props.$error ? global.colors.feedback3 : global.control.border.color};
  }

  && textarea:hover {
    border-color: ${(props) =>
      props.$error ? global.colors.feedback3 : global.control.border.color};
    outline-color: ${(props) =>
      props.$error ? global.colors.feedback3 : global.control.border.color};
  }

  && button {
    border-radius: 0;
  }

  && button:hover {
    border-color: ${(props) =>
      props.$error ? global.colors.feedback3 : global.control.border.color};
    outline-color: ${(props) =>
      props.$error ? global.colors.feedback3 : global.control.border.color};
  }

  && div#radial,
  div#checkboxicon {
    border-color: ${(props) =>
      props.$error
        ? global.colors.feedback3
        : global.control.border.color} !important;
  }

  && div#radial-checked,
  div#checkboxicon-checked {
    border-color: ${(props) =>
      props.$error
        ? global.colors.feedback3
        : global.control.border.color} !important;
    background-color: ${(props) =>
      props.$error ? global.colors.feedback3 : global.control.border.color};
  }
`;

export const Label = styled(Subtitle)`
  margin-bottom: 8px;
  margin-top: 0px;
`;

export const Message = styled(Caption)`
  margin-top: 8px;
  font-weight: 700;
  color: ${global.colors.feedback3};
`;

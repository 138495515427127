import styled from 'styled-components';
import Description from '../Typography/Description';
import { global } from '../Theme/tokens';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 180px;
  background-color: ${global.colors.neutral1};
  color: white;
  padding: 16px;
  border-radius: 4px;
  gap: 12px;
  margin-top: 8px;
  box-shadow: 0px 4px 8px 0px #b7bac13d;
`;

export const StyledDescription = styled(Description)`
  font-weight: 700;
  line-height: 100%;
`;

export const ChildrenContent = styled.div`
  display: inherit;
  cursor: pointer;
`;

const options = [
  {
    numberValue: 0,
    value: 'NaoConheco',
    text: 'Não conheço',
    description: 'Ainda não conheço bem o assunto'
  },
  {
    numberValue: 1,
    value: 'LeioBastante',
    text: 'Leio bastante',
    description:
      'Estou aprendendo sobre o assunto, mas ainda não apliquei ou coloquei em prática'
  },
  {
    numberValue: 2,
    value: 'FizAlgunsTrabalhos',
    text: 'Fiz alguns trabalhos',
    description:
      'Conheço o suficiente sobre o assunto para aplicar em alguns trabalhos/projetos'
  },
  {
    numberValue: 3,
    value: 'TrabalhoRegularmente',
    text: 'Trabalho regularmente',
    description: 'Faço uso desse tipo de conhecimento no meu dia a dia'
  },
  {
    numberValue: 4,
    value: 'PlenoConhecimento',
    text: 'Pleno conhecimento',
    description:
      'Sou capaz de ensinar sobre este conteúdo e adaptá-lo para diferentes contextos'
  }
];

export default options;

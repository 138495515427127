import React, { useEffect, useState } from 'react';
import { CheckBox as GrommetCheckBox, CheckBoxExtendedProps } from 'grommet';

import { useCheckboxGroup } from '../CheckboxGroup';
import { global } from '../Theme/tokens';
import Icon from '../Icon';

import { CheckContent } from './styles';

const CheckBox: React.FC<CheckBoxExtendedProps> = ({
  label,
  checked: checkedProp,
  name: nameProp,
  value,
  onChange,
  disabled,
  className,
  ...props
}) => {
  const checkboxGroup = useCheckboxGroup();
  const [checked, setChecked] = useState(checkedProp);
  const [name, setName] = useState(nameProp);

  useEffect(() => {
    if (checkboxGroup) {
      setChecked(checkboxGroup.value.includes(value));
      setName(checkboxGroup.name);
    } else {
      setChecked(checkedProp);
    }
  }, [checkboxGroup, checkedProp]);

  return (
    <div className={className}>
      <GrommetCheckBox
        {...props}
        checked={checked}
        name={name}
        value={value}
        label={label}
        disabled={disabled}
        onChange={(event) => {
          if (checkboxGroup) {
            checkboxGroup.onChange(
              event.target.checked
                ? [...checkboxGroup.value, value]
                : checkboxGroup.value.filter((item) => item !== value),
              event
            );
          } else if (onChange) {
            onChange(event);
          }
        }}
      >
        {({ checked }) => (
          <CheckContent
            id={checked ? 'checkboxicon-checked' : 'checkboxicon'}
            $checked={checked}
          >
            {checked && (
              <Icon icon='check' size={16} color={global.colors.neutral5} />
            )}
          </CheckContent>
        )}
      </GrommetCheckBox>
    </div>
  );
};

export default CheckBox;

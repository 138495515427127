import styled from 'styled-components';
import { Spinner } from 'grommet';
import { global } from '../Theme/tokens';

export const StyledSpinner = styled(Spinner)<{ $inverse: boolean }>`
  border-width: 2px;
  padding: 0px;
  border-color: ${(props) =>
    props.$inverse ? global.colors.brand4 : global.colors.neutral3};
  border-top-color: ${(props) =>
    props.$inverse ? global.colors.neutral5 : global.colors.brand};
`;

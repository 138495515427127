import styled from 'styled-components';
import global from '../Theme/tokens/global';

export const CheckContent = styled.div<{ $checked: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-style: solid;
  border-color: ${global.control.border.color} !important;
  background-color: ${(props) =>
    props.$checked ? global.colors.brand : global.colors.neutral5};

  & i {
    margin-bottom: 2px;
  }
`;

import React from 'react';
import { StyledDivider } from './styles';

interface IDividerProps {
  size?: number;
  margin?: number;
  inverse?: boolean;
}

const Divider: React.FC<IDividerProps> = ({
  size = 2,
  margin = 24,
  inverse = false
}) => <StyledDivider $size={size} $margin={margin} $inverse={inverse} />;

export default Divider;

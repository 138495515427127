import { useContext } from 'react';

import CheckboxGroupContext, {
  ICheckboxGroupContextProps
} from './CheckboxGroupContext';

function useCheckboxGroup(): ICheckboxGroupContextProps | undefined {
  return useContext(CheckboxGroupContext);
}

export default useCheckboxGroup;

import React from 'react';
import { global } from '../Theme/tokens';
import { StyledIcon } from './styles';

interface IIconProps {
  icon: string;
  color?: string;
  size?: number;
}

const Icon: React.FC<IIconProps> = ({
  icon,
  color = global.colors.brand,
  size = 24
}) => (
  <StyledIcon className='material-icons' $color={color} $size={size}>
    {icon}
  </StyledIcon>
);

export default Icon;

import global from './global';
import { css } from 'styled-components';

const radioButton = {
  border: { color: global.colors.brand },
  hover: {
    border: {
      color: global.colors.brand3
    }
  },
  container: {
    extend: css`
      color: ${global.colors.neutral1};
      font-weight: 400;
      font-size: 16px;
      transition: all 0.2s ease-in-out;
      & div#radial-checked {
        background-color: ${global.colors.brand};
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
        & > svg {
          stroke: #fff;
          & circle {
            cx: 8;
            cy: 8;
            r: 4;
            fill: #fff;
          }
        }
      }
      & div#radial,
      div#radial-checked {
        box-sizing: border-box;
        max-width: 100%;
        border: solid 2px ${global.colors.brand};
        min-width: 0;
        min-height: 0;
        height: 20px;
        width: 20px;
        border-radius: 100%;
      }
      &:hover {
        opacity: 0.7;
      }
    `
  },
  extend: css`
    margin-right: 12px;
  `
};

export default radioButton;

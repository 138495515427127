import styled from 'styled-components';
import { Button } from 'grommet';

export const StyledButton = styled(Button)<{ $loading: boolean }>`
  position: relative;
  pointer-events: ${(props) => (props.$loading ? 'none' : 'initial')};
`;

export const Label = styled.span<{ $loading: boolean }>`
  opacity: ${(props) => (props.$loading ? 0 : 1)};
`;

export const SpinnerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import React from 'react';
import { Grommet } from 'grommet';
import { Provider, positions } from 'react-alert';
import { ThemeType } from 'grommet/themes';
import {
  global,
  input,
  button,
  select,
  checkBox,
  radioButton,
  heading,
  paragraph,
  text,
  textArea,
  layer,
  rangeInput
} from './tokens';
import FontLoader from './FontLoader';
import Alert from '../Alert';

const theme: ThemeType = {
  global,
  textInput: input,
  maskedInput: input,
  button,
  select,
  checkBox,
  radioButton,
  spinner: { container: { color: global.colors.brand } },
  heading,
  paragraph,
  text,
  textArea,
  tip: { drop: { margin: { left: '-10px' } } },
  layer,
  rangeInput
};

const Theme: React.FC = ({ children, ...props }) => (
  <Grommet theme={theme} {...props}>
    <FontLoader />
    <Provider template={Alert} timeout={5000} position={positions.BOTTOM_RIGHT}>
      {children}
    </Provider>
  </Grommet>
);

export default Theme;

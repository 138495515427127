import React from 'react';
import { Select as GrommetSelect, SelectExtendedProps } from 'grommet';
import FormControl, { IFormControlProps } from '../FormControl';

const Select: React.FC<SelectExtendedProps & IFormControlProps> = ({
  label,
  message,
  error = false,
  ...props
}) => (
  <FormControl label={label} message={message} error={error}>
    <GrommetSelect {...props} />
  </FormControl>
);

export default Select;

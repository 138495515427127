import React, { useState } from 'react';
import FormControl, { IFormControlProps } from '../FormControl';
import RadioGroupContext from './RadioGroupContext';
import { StyledRadioGroup } from './styles';

interface IRadioGroupProps extends IFormControlProps {
  value?: any;
  name: string;
  onChange?: (value: any, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioGroup: React.FC<IRadioGroupProps> = ({
  label,
  message,
  error = false,
  children,
  name,
  value: valueProp,
  onChange
}) => {
  const [value, setValue] = useState(valueProp);

  const providerValue = {
    name,
    onChange: (value, event) => {
      setValue(value);

      if (onChange) {
        onChange(value, event);
      }
    },
    value
  };

  return (
    <RadioGroupContext.Provider value={providerValue}>
      <FormControl label={label} message={message} error={error}>
        <StyledRadioGroup role='radiogroup'>{children}</StyledRadioGroup>
      </FormControl>
    </RadioGroupContext.Provider>
  );
};

export default RadioGroup;

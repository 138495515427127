import styled from 'styled-components';
import { global } from '../Theme/tokens';

export const Container = styled.ul`
  display: flex;
  flex-direction: column;
  width: '100%';
  color: ${global.colors.brand};
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const Item = styled.li`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;

  & i {
    margin-right: 4px;
  }
`;

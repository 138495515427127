import global from './global';

const checkbox = {
  gap: '12px',
  extend: () => `
    color: ${global.colors.neutral1};
    font-size: 16px;

    &:hover {
      opacity: 0.7;
    }
  `,
  check: {
    extend: () => `
      &:not(:empty) {
        background: ${global.colors.brand};
      }

      &[disabled] {
        border: 2px solid ${global.colors.neutral3};

        &:not(:empty) {
          background: ${global.colors.neutral3};
        }
      }
    `
  }
};

export default checkbox;

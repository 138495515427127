import globalTokens from './global';

const input = {
  extend: () => `
    font-size: 16px;
    background: #ffffff;
    border-radius: 0px;
    color: ${globalTokens.colors.text};
    font-weight: 400;
  `,
  placeholder: {
    extend: () => `
      color: ${globalTokens.colors.neutral4};
      text-transform: capitalize;
    `
  }
};

export default input;

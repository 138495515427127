import React, { useState, useEffect } from 'react';

import options from '../Graphic/options';
import { GraphicProps, ValueType } from '../Graphic';
import Caption from '../Typography/Caption';
import global from '../Theme/tokens/global';
import Tooltip from '../Tooltip';
import Icon from '../Icon';

import { StyledRangeInput, LabelContent } from './styles';
import { Title, TitleContent } from '../Graphic/styles';

const GraphicRange: React.FC<GraphicProps> = ({
  label,
  message,
  tooltipDescription,
  error = false,
  value: valueProp,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(valueProp);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const fullValue = options.find((item) => item.value === value);

  return (
    <div>
      <TitleContent>
        <Title>{label}</Title>
        {tooltipDescription && (
          <Tooltip title={`${label}:`} description={tooltipDescription}>
            <Icon size={24} icon='info' color={global.colors.neutral4} />
          </Tooltip>
        )}
      </TitleContent>
      <StyledRangeInput
        value={fullValue?.numberValue || 0}
        step={1}
        min={0}
        max={4}
        $error={error}
        onChange={(e) => {
          const newValue = options.find(
            (item) => `${item.numberValue}` === e.target.value
          );

          setValue(newValue?.value as ValueType);

          if (onChange) {
            onChange(newValue?.value as ValueType);
          }
        }}
        {...props}
      />
      {fullValue && (
        <LabelContent $value={fullValue.numberValue} $error={error}>
          <Caption>{fullValue.text}</Caption>
        </LabelContent>
      )}
      {!fullValue && message && (
        <LabelContent $value={0} $error>
          <Caption>{message}</Caption>
        </LabelContent>
      )}
    </div>
  );
};

export default GraphicRange;

import React from 'react';
import { AlertComponentPropsWithStyle } from 'react-alert';

import { global } from '../Theme/tokens';
import Icon from '../Icon';

import { Content, CloseButton, Text } from './styles';

const alertMap = {
  info: { color: global.colors.feedback2, icon: 'info' },
  success: { color: global.colors.feedback1, icon: 'check_circle' },
  error: { color: global.colors.feedback3, icon: 'error' },
  warning: { color: global.colors.feedback4, icon: 'warning' }
};

const Alert: React.FC<AlertComponentPropsWithStyle> = ({
  message,
  options,
  close,
  style
}) => {
  const alert = options.type ? alertMap[options.type] : alertMap.info;

  return (
    <Content style={style} $color={alert.color}>
      <Icon icon={alert.icon} size={24} color={global.colors.neutral5} />
      <Text>{message}</Text>
      <CloseButton onClick={close}>
        <Icon icon='close' size={24} color={global.colors.neutral5} />
      </CloseButton>
    </Content>
  );
};

export default Alert;

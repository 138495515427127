import React from 'react';
import {
  TextInputProps,
  TextInput,
  MaskedInput,
  MaskedInputExtendedProps
} from 'grommet';
import Icon from '../Icon';
import FormControl, { IFormControlProps } from '../FormControl';
import {
  LabelStyled,
  ButtonStyled,
  PasswordContainer,
  IconContainer,
  FileInputContainer,
  FileInputStyled
} from './styles';
import { global } from '../Theme/tokens';

const Input: React.FC<
  TextInputProps & MaskedInputExtendedProps & IFormControlProps
> = ({
  mask,
  label,
  message,
  error = false,
  type = 'text',
  id,
  onChange,
  className,
  ...props
}) => {
  const [reveal, setReveal] = React.useState(false);

  if (type === 'password') {
    return (
      <FormControl label={label} message={message} error={error}>
        <PasswordContainer className={className}>
          <TextInput
            type={reveal ? 'text' : 'password'}
            onChange={onChange}
            {...props}
          />
          <IconContainer>
            <ButtonStyled type='button' onClick={() => setReveal(!reveal)}>
              {reveal ? (
                <Icon icon='visibility' color={global.colors.neutral4} />
              ) : (
                <Icon icon='visibility_off' color={global.colors.neutral4} />
              )}
            </ButtonStyled>
          </IconContainer>
        </PasswordContainer>
      </FormControl>
    );
  }

  if (type === 'file') {
    return (
      <FileInputContainer>
        <LabelStyled htmlFor={id}>{label}</LabelStyled>
        <FileInputStyled type={type} id={id} {...props} onChange={onChange} />
      </FileInputContainer>
    );
  }

  if (mask) {
    return (
      <FormControl label={label} message={message} error={error}>
        <MaskedInput mask={mask} type={type} {...props} onChange={onChange} />
      </FormControl>
    );
  }

  return (
    <FormControl label={label} message={message} error={error}>
      <TextInput type={type} {...props} onChange={onChange} />
    </FormControl>
  );
};

export default Input;

import global from './global';

const button = {
  font: { weight: 'bold', size: '14px', family: 'Montserrat' },
  border: {
    radius: '0px'
  },
  padding: {
    vertical: '12px',
    horizontal: '32px'
  },
  size: {
    small: {
      border: { radius: '0px' },
      pad: {
        vertical: '6px',
        horizontal: '24px'
      }
    }
  },
  extend: () => `
    font-weight: 700;
    color: ${global.colors.brand};
    
    &:hover {
      border-color: ${global.colors.brand3};
      color: ${global.colors.brand3};
      box-shadow: none;
    }
  `,
  primary: {
    extend: () => `
      && {
        color: #fff;
        border-color: ${global.colors.brand};

        &:hover {
          color: #fff;
          background-color: ${global.colors.brand3};
        }
      }
    `
  },
  disabled: {
    opacity: '.1',
    primary: {
      color: global.colors.neutral3,
      extend: () => `
      font-weight: bold;
      color: #fff;
      &:hover {
        background-color: #cfd2d7;
        border: 1px solid #cfd2d7;
        box-shadow: 0px 0px 0px 2px #cfd2d7;
      }
    `
    }
  }
};

export default button;

import { useContext } from 'react';

import RadioGroupContext, {
  IRadioGroupContextProps
} from './RadioGroupContext';

function useRadioGroup(): IRadioGroupContextProps | undefined {
  return useContext(RadioGroupContext);
}

export default useRadioGroup;

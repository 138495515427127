import global from './global';

const textArea = {
  extend: () => `
    font-size: 16px;
    background: #ffffff;
    border-radius: 0px;
    color: ${global.colors.text};
    font-weight: 400;
    min-height: 120px;
  `
};

export default textArea;

const layer = {
  extend: () => `
    background-color:#2D3040B3
  `,
  container: {
    extend: () => `
      border-radius: 16px !important;
      width: 560px !important;
      max-width: calc(100% - 80px) !important;
      max-height: calc(100% - 80px) !important;
      padding: 40px;
      height: initial !important;
      padding: 40px;
      overflow-y: auto;
    `
  }
};

export default layer;

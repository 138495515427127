import styled from 'styled-components';
import globalTokens from '../Theme/tokens/global';

export const LinkStyled = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

export const LabelStyled = styled.span<{ $size: number; $inverse: boolean }>`
  color: ${(props) =>
    props.$inverse ? globalTokens.colors.neutral5 : globalTokens.colors.brand};
  text-decoration: underline;
  font-size: ${(props) => props.$size}px;
  font-weight: bold;
`;

export const ButtonStyled = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  font-family: 'Montserrat';
  font-weight: bold;
  padding: 0;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

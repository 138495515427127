import styled from 'styled-components';
import { Row, Container } from 'react-grid-system';

export const StyledContainer = styled(Container)`
  padding: 0px !important;
  max-width: initial !important;
  margin: initial !important;
`;

export const StyledRow = styled(Row)`
  margin: -20px -8px !important;
`;

import React, { Fragment } from 'react';
import Caption from '../Typography/Caption';
import Icon from '../Icon';
import { global } from '../Theme/tokens';
import { Container, Item } from './styles';

interface ICheckerProps {
  messages: string[];
  rules?: boolean[];
}

const Checker: React.FC<ICheckerProps> = ({ messages, rules = [] }) => (
  <Container>
    {messages.map((message, index) => (
      <Item aria-label={message} aria-checked={rules[index]}>
        {rules[index] !== undefined && (
          <Fragment>
            {rules[index] ? (
              <Icon icon='check' size={14} color={global.colors.feedback1} />
            ) : (
              <Icon icon='close' size={14} color={global.colors.feedback3} />
            )}
          </Fragment>
        )}
        <Caption>{message}</Caption>
      </Item>
    ))}
  </Container>
);

export default Checker;

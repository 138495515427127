import React from 'react';
import globalTokens from '../Theme/tokens/global';
import Icon from '../Icon';
import { LinkStyled, LabelStyled, ButtonStyled } from './styles';

interface ILinkProps {
  icon?: string | undefined;
  label: string | undefined;
  href?: string | undefined;
  size?: number | undefined;
  inverse?: boolean | undefined;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Link: React.FC<ILinkProps> = ({
  icon,
  label,
  href,
  size = 16,
  inverse = false,
  ...props
}) => (
  <div>
    {href ? (
      <LinkStyled href={href} {...props}>
        {icon && (
          <Icon
            icon={icon}
            color={
              inverse ? globalTokens.colors.neutral5 : globalTokens.colors.brand
            }
            size={size}
          />
        )}
        <LabelStyled $inverse={inverse} $size={size}>
          {label}
        </LabelStyled>
      </LinkStyled>
    ) : (
      <ButtonStyled type='button' {...props}>
        {icon && (
          <Icon
            icon={icon}
            color={
              inverse ? globalTokens.colors.neutral5 : globalTokens.colors.brand
            }
            size={size}
          />
        )}
        <LabelStyled $inverse={inverse} $size={size}>
          {label}
        </LabelStyled>
      </ButtonStyled>
    )}
  </div>
);

export default Link;

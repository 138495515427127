import React from 'react';

export interface IRadioGroupContextProps {
  name: string;
  onChange: (value: any, event: React.ChangeEvent<HTMLInputElement>) => void;
  value: any;
}

const RadioGroupContext = React.createContext<
  IRadioGroupContextProps | undefined
>(undefined);

export default RadioGroupContext;

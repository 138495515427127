import styled from 'styled-components';

export const ProgressBarContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProgressBarStyled = styled.progress`
  border-radius: 0 4px 4px 0;
  height: 24px; 
  border: 1px solid #716CAF; 
  &::-webkit-progress-value {
    background-color: #716CAF;
  }
`;

export const ProgressText = styled.p`
  font-family: Montserrat;
  color: #2D3040;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin: 0 0 0 16px;
`; 
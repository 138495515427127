import styled from 'styled-components';
import { global } from '../Theme/tokens';
import Caption from '../Typography/Caption';

export const HorizontalStepperContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: '100%';
  justify-content: space-between;
  position: relative;

  &::before {
    content: '';
    width: calc(100% - 90px);
    height: 1px;
    position: absolute;
    z-index: 0;
    top: 16px;
    left: 45px;
    display: block;
    border-top: 2px solid
      ${(props) =>
        `${props.inverse ? global.colors.brand3 : global.colors.neutral3}`};

    @media only screen and (max-width: 768px) {
      width: calc(100% - 64px);
      left: 32px;
    }
  }
`;

export const VerticalStepperContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HorizontalStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: fit-content;
`;

export const VerticalStep = styled.div`
  display: flex;
  position: relative;
  min-width: fit-content;
  flex-direction: row;
  align-items: flex-start;
  width: fit-content;
  height: 72px;
  flex: initial;

  &:first-child {
    &::before {
      border-right: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    display: block;
    width: 0px;
    height: 32px;
    left: 15px;
    top: -50%;
    border-right: 2px solid
      ${(props) =>
        props.inverse ? global.colors.brand3 : global.colors.neutral3};
  }
`;

export const HorizontalTitle = styled(Caption)`
  transition: all 0.2s ease-in-out;
  font-weight: ${(props) => (props.selected ? 600 : 400)};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  width: 90px;
  text-align: center;
  margin-top: 16px;
  color: ${global.colors.brand};

  ${(props) => props.inverse && `color: #FFF;`}

  @media only screen and (max-width: 768px) {
    width: 64px;
    margin-top: 8px;
    opacity: ${(props) => (props.selected ? 1 : 0)};
  }
`;

export const VerticalTitle = styled(Caption)`
  transition: all 0.2s ease-in-out;
  font-weight: ${(props) => (props.selected ? 600 : 400)};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  width: fit-content;
  margin-top: 8px;
  margin-left: 16px;
  text-align: left;

  ${(props) => props.inverse && `color: #FFF;`}
`;

export const Bullet = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  background: ${(props) => props.bgColor || global.colors.brand3};
  border-radius: 100%;
  outline: ${(props) =>
    props.status === 'unchecked'
      ? `1px solid ${global.colors.neutral3}`
      : `none`};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};

  & h4 {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    & h4 {
      display: ${(props) =>
        props.variant === 'horizontal' && props.status !== 'success'
          ? 'initial'
          : 'none'};
    }

    & i {
      display: ${(props) =>
        props.variant === 'horizontal' && props.status !== 'success'
          ? 'none'
          : 'initial'};
    }
  }
`;

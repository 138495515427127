const global = {
  colors: {
    brand: '#363e71',
    brand2: '#dee4ff',
    brand3: '#716caf',
    brand4: '#464f8e',
    neutral1: '#2d3040',
    neutral2: '#f8f8f8',
    neutral3: '#cfd2d7',
    neutral4: '#9fa2a9',
    neutral5: '#ffffff',
    feedback1: '#5daf8f',
    feedback2: '#3a65b9',
    feedback3: '#c2463d',
    feedback4: '#ffc062',
    text: '#2d3040'
  },
  control: {
    border: {
      color: '#363e71'
    }
  },
  focus: {
    outline: {
      color: '#363e71',
      size: '1px'
    }
  },
  font: {
    family: 'Montserrat'
  }
};

export default global;

import React from 'react';
import { TextAreaExtendedProps, TextArea } from 'grommet';
import FormControl, { IFormControlProps } from '../FormControl';

const Textarea: React.FC<TextAreaExtendedProps & IFormControlProps> = ({
  label,
  message,
  error = false,
  ...props
}) => (
  <FormControl label={label} message={message} error={error}>
    <TextArea resize='vertical' {...props} />
  </FormControl>
);

export default Textarea;

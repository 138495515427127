import { RangeInput } from 'grommet';
import styled from 'styled-components';
import { global } from '../Theme/tokens';

const valueMap = [
  {
    marginLeft: 0,
    width: '100%',
    textAlign: 'left',
    background: global.colors.neutral2,
    errorBackground: global.colors.neutral2
  },
  {
    marginLeft: 0,
    width: '51%',
    textAlign: 'center',
    background: `linear-gradient(to right, ${global.colors.brand} 25%, ${global.colors.neutral2} 25%)`,
    errorBackground: `linear-gradient(to right, ${global.colors.feedback3} 25%, ${global.colors.neutral2} 25%)`
  },
  {
    marginLeft: 0,
    width: '100%',
    textAlign: 'center',
    background: `linear-gradient(to right, ${global.colors.brand} 50%, ${global.colors.neutral2} 50%)`,
    errorBackground: `linear-gradient(to right, ${global.colors.feedback3} 50%, ${global.colors.neutral2} 50%)`
  },
  {
    marginLeft: '50%',
    width: '50%',
    textAlign: 'center',
    background: `linear-gradient(to right, ${global.colors.brand} 75%, ${global.colors.neutral2} 75%)`,
    errorBackground: `linear-gradient(to right, ${global.colors.feedback3} 75%, ${global.colors.neutral2} 75%)`
  },
  {
    marginLeft: 0,
    width: '100%',
    textAlign: 'right',
    background: global.colors.brand,
    errorBackground: global.colors.feedback3
  }
];

export const StyledRangeInput = styled(RangeInput)<{ $error: boolean }>`
  &::-webkit-slider-runnable-track {
    height: 2px;
    background: ${(props) =>
      props.$error
        ? valueMap[props.value].errorBackground
        : valueMap[props.value].background};
  }

  &::-moz-range-progress {
    height: 2px;
    background: ${(props) =>
      props.$error
        ? valueMap[props.value].errorBackground
        : valueMap[props.value].background};
  }

  &&::-webkit-slider-thumb {
    background: ${(props) =>
      props.$error ? global.colors.feedback3 : global.colors.brand};
  }

  &&::-moz-range-thumb {
    background: ${(props) =>
      props.$error ? global.colors.feedback3 : global.colors.brand};
  }
`;

export const LabelContent = styled.div<{ $value: number; $error: boolean }>`
  text-align: ${(props) => valueMap[props.$value].textAlign};
  margin-left: ${(props) => valueMap[props.$value].marginLeft};
  width: ${(props) => valueMap[props.$value].width};
  font-weight: 600;
  margin-top: 6px;
  color: ${(props) =>
    props.$error ? global.colors.feedback3 : global.colors.brand};
`;

import React, { useEffect, useState } from 'react';

import Tooltip from '../Tooltip';
import Icon from '../Icon';
import global from '../Theme/tokens/global';
import options from './options';

import {
  GraphicContainer,
  Item,
  Option,
  StyledCaption,
  Title,
  TitleContent,
  Message
} from './styles';

export type ValueType =
  | 'NaoConheco'
  | 'LeioBastante'
  | 'FizAlgunsTrabalhos'
  | 'TrabalhoRegularmente'
  | 'PlenoConhecimento';

export interface GraphicProps {
  value?: ValueType;
  label: string;
  onChange?: (value: ValueType) => void;
  tooltipDescription?: string;
  message?: string;
  error?: boolean;
}

const Graphic: React.FC<GraphicProps> = ({
  label,
  value: valueProp,
  onChange,
  tooltipDescription,
  message,
  error = false
}) => {
  const [value, setValue] = useState(valueProp);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const fullValue = options.find((item) => item.value === value);

  return (
    <div>
      <TitleContent>
        <Title>{label}</Title>
        {tooltipDescription && (
          <Tooltip title={`${label}:`} description={tooltipDescription}>
            <Icon size={24} icon='info' color={global.colors.neutral4} />
          </Tooltip>
        )}
      </TitleContent>
      <GraphicContainer aria-label={label} role='group'>
        {options.map((item) => {
          const checked =
            fullValue && item.numberValue <= fullValue.numberValue;

          return (
            <Item
              role='radio'
              aria-checked={checked}
              aria-label={item.text}
              onClick={() => {
                setValue(item.value as ValueType);

                if (onChange) {
                  onChange(item.value as ValueType);
                }
              }}
              key={item.value}
            >
              <Tooltip description={item.description}>
                <Option $error={error} $checked={checked} />
                <StyledCaption $checked={checked}>{item.text}</StyledCaption>
              </Tooltip>
            </Item>
          );
        })}
      </GraphicContainer>
      {message && <Message>{message}</Message>}
    </div>
  );
};

export default Graphic;

import styled from 'styled-components';
import { global } from '../Theme/tokens';
import Caption from '../Typography/Caption';
import Paragraph from '../Typography/Paragraph';

export const GraphicContainer = styled.div`
  display: flex;
  gap: 2px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  & > div {
    display: flex;
    flex-direction: column;
  }

  &:hover span {
    color: ${(props) =>
      props.$checked ? global.colors.brand4 : global.colors.brand4};
  }

  &:first-child > div > div {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-child > div > div {
    border-radius: 0px 4px 4px 0px;
  }
`;

export const Option = styled.div<{ $checked: boolean; $error: boolean }>`
  width: 100%;
  height: 24px;
  margin-bottom: 16px;
  transition: all 0.2s ease-in-out;
  background-color: ${(props) =>
    props.$checked ? global.colors.brand3 : global.colors.brand2};
  border: ${(props) =>
    props.$error ? `2px solid ${global.colors.feedback3}` : 'none'};
`;

export const StyledCaption = styled(Caption)<{ $checked: boolean }>`
  font-weight: 600;
  margin-right: 5px;
  transition: all 0.2s ease-in-out;
  color: ${(props) =>
    props.$checked ? global.colors.neutral1 : global.colors.brand3};
`;

export const Title = styled(Paragraph)`
  margin-bottom: 0;
  margin-top: 0;
`;

export const TitleContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;

export const Message = styled(Caption)`
  margin-top: 16px;
  font-weight: 700;
  color: ${global.colors.feedback3};
  float: left;
`;

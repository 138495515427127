import React, { useEffect, useState } from 'react';
import { Radial } from 'grommet-icons';
import {
  Box,
  RadioButton as GrommetRadioButton,
  RadioButtonExtendedProps
} from 'grommet';

import Tooltip from '../Tooltip';
import Icon from '../Icon';
import global from '../Theme/tokens/global';
import { useRadioGroup } from '../RadioGroup';
import { Content } from './style';

interface IRadioProps extends Omit<RadioButtonExtendedProps, 'name'> {
  name?: string;
  tooltip?: { title?: string; description?: string };
}

const Radio: React.FC<IRadioProps> = ({
  name: nameProp,
  checked: checkedProp,
  value,
  label,
  className,
  onChange,
  tooltip,
  ...props
}) => {
  const radioGroup = useRadioGroup();
  const [checked, setChecked] = useState(checkedProp);
  const [name, setName] = useState(nameProp);

  useEffect(() => {
    if (radioGroup) {
      setChecked(radioGroup.value === value);
      setName(radioGroup.name);
    } else {
      setChecked(checkedProp);
    }
  }, [radioGroup, checkedProp]);

  return (
    <Content className={className}>
      <GrommetRadioButton
        {...props}
        name={name || ''}
        checked={checked}
        value={value}
        label={label}
        onChange={(event) => {
          if (radioGroup) {
            radioGroup.onChange(value, event);
          } else if (onChange) {
            onChange(event);
          }
        }}
      >
        {({ checked }) => (
          <Box id={checked ? 'radial-checked' : 'radial'}>
            {checked && <Radial />}
          </Box>
        )}
      </GrommetRadioButton>
      {tooltip && (
        <Tooltip title={tooltip.title} description={tooltip.description}>
          <Icon size={20} icon='info' color={global.colors.neutral4} />
        </Tooltip>
      )}
    </Content>
  );
};

export default Radio;

import React from 'react';
import { StyledContainer, StyledRow } from './styles';

const Row: React.FC = ({ children }) => (
  <StyledContainer>
    <StyledRow>{children}</StyledRow>
  </StyledContainer>
);

export default Row;

import global from './global';

const select = {
  container: {
    extend: () => `
      & button[role=menuitem] {
        background: transparent;

        &:hover {
          background: ${global.colors.neutral2};
        }
      }

      & button[role=menuitem] div {
        padding: 16px;
      }

      & button[role=menuitem] span {
        font-size: 16px;
        font-weight: 400;
        color: ${global.colors.text};
        line-height: 100%;
      }
    `
  },
  control: {
    extend: () => `
      &:disabled {
        background-color: ${global.colors.neutral2};
        opacity: 1;

        input:read-only {
          background-color: ${global.colors.neutral2};
          opacity: 1;
        }
      }
    `
  }
};

export default select;

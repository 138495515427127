import React, { Fragment } from 'react';
import { ButtonExtendedProps } from 'grommet';
import Spinner from '../Spinner';

import { SpinnerContent, StyledButton, Label } from './styles';

interface IButtonProps extends ButtonExtendedProps {
  loading?: boolean;
}

const Button: React.FC<IButtonProps & JSX.IntrinsicElements['a']> = ({
  loading,
  primary,
  label,
  size,
  ...props
}) => (
  <StyledButton size={size} $loading={loading} primary={primary} {...props}>
    {() => (
      <Fragment>
        {loading && (
          <SpinnerContent>
            <Spinner
              size={size === 'small' ? 'xsmall' : 'small'}
              inverse={primary}
            />
          </SpinnerContent>
        )}
        <Label $loading={loading}>{label}</Label>
      </Fragment>
    )}
  </StyledButton>
);

export default Button;

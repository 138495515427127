import React, { Fragment } from 'react';
import { Layer } from 'grommet';

import { global } from '../Theme/tokens';
import Subtitle from '../Typography/Subtitle';
import Divider from '../Divider';
import Icon from '../Icon';

import { TitleContent, IconButton, ActionsContent } from './styles';

interface IModalProps {
  show: boolean;
  title: string;
  onClose?: () => void;
  actions?: React.ReactNode;
  className?: string;
  canClose?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  show,
  title,
  onClose,
  children,
  actions,
  className,
  canClose = true
}) => {
  if (!show) {
    return null;
  }

  return (
    <Layer className={className} onEsc={onClose} onClickOutside={onClose}>
      <TitleContent>
        <Subtitle large>{title}</Subtitle>
        {!canClose && (
          <IconButton onClick={onClose}>
            <Icon icon='close' size={24} color={global.colors.brand4} />
          </IconButton>
        )}
      </TitleContent>
      {children}
      {actions && (
        <Fragment>
          <Divider size={1} margin={40} />
          <ActionsContent>{actions}</ActionsContent>
        </Fragment>
      )}
    </Layer>
  );
};

export default Modal;

const text = {
  xsmall: {
    size: '12px',
    height: '16px'
  },
  small: { size: '12px' },
  medium: { size: '14px' }
};

export default text;

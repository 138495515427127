import React from 'react';
import { HeadingProps } from 'grommet';
import { StyledSubtitle } from './styles';

interface ISubtitleProps extends Omit<HeadingProps, 'level'> {
  large?: boolean;
}

const Subtitle: React.FC<ISubtitleProps> = ({ large = false, ...props }) => (
  <StyledSubtitle margin='none' $large={large} level={4} {...props} />
);

export default Subtitle;
